import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { getElectricDataDuration } from "../../../model/slice/dataDurationSlice";
import {
  fetchDateRangeGeneratorData,
  fetchGeneratorEngineData,
  fetchShaftGeneratorEngineData,
} from "../../../api/baseEngineData";
import ElectricWorker from "./worker/electricGraphData.worker";
import * as Comlink from "comlink";
import { calDurations } from "../../../constants/calDuration";
import { logger } from "../../../api/logger";
import { timeKey } from "../../../constants/timeKey";

let cancelToken;

export const useElectricData = ({ vesselId = null, ge, sg }) => {
  const [graphData, setGraphData] = useState([]);
  const { startDate, endDate } = useSelector(getElectricDataDuration);
  const unmount = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNoData, setIsNoData] = useState(false);

  const fetchBase = [
    {
      getFetchData: async ({ startDate, endDate, interval }) =>
        await fetchGeneratorEngineData({
          vesselId: vesselId,
          startDate: startDate,
          endDate: endDate,
          interval: interval,
          cancelToken: cancelToken?.token,
        }),
      getDateRangeData: async (vessel_id, dateRange) =>
        await fetchDateRangeGeneratorData(vessel_id, dateRange),
      dateRangeKey: "dateRangeGeneratorData",
      dataKey: "ge",
      fetchKey: "loadingData",
      data: [],
    },
    {
      dateRangeKey: "dateRangeShaftGeneratorData",
      getFetchData: async ({ startDate, endDate, interval }) =>
        await fetchShaftGeneratorEngineData({
          vesselId: vesselId,
          startDate: startDate,
          endDate: endDate,
          interval: interval,
          cancelToken: cancelToken?.token,
        }),
      getDateRangeData: async (vessel_id, dateRange) =>
        await fetchDateRangeGeneratorData(vessel_id, dateRange),
      dataKey: "sg",
      fetchKey: "loadingData",
      data: [],
    },
  ];

  const getElectricValue = async (electricBase) => {
    let data;

    const interval = calDurations({ startDate, endDate });

    logger.info(`getElectricValue -- GE: ${ge.length}, SG: ${sg.length}`);
    const promiseList = () => {
      if (electricBase.dataKey === "ge") {
        return ge === 0
          ? []
          : [electricBase.getFetchData({
                  startDate,
                  endDate,
                  interval,
                })
              ];
      } else if (electricBase.dataKey === "sg") {
        return sg === 0
          ? []
          : [electricBase.getFetchData({
                  startDate,
                  endDate,
                  interval,
                })
              ];
      }
      logger.info(`error data: ${electricBase.dataKey}`);
    };
    const fetchData = await Promise.all([...promiseList()]);
    data = fetchData.map((d) => d[electricBase?.fetchKey]).flat();

    return {
      ...electricBase,
      data,
    };
  };

  const createNewGraphData = async ({ sgData, geData }) => {
    const worker = ElectricWorker();
    const wrap = Comlink.wrap(worker);
    const newData = await wrap?.createGraphData({
      sgData: sgData,
      geData: geData,
      startDate,
      endDate,
    });
    const key = timeKey(newData);
    unmount.current ||
      setGraphData(newData.sort((a, b) => new Date(a[key]).getTime() - new Date(b[key]).getTime()));
    unmount.current || setIsNoData(!newData?.length);
    worker.terminate();
  };

  const dataLoad = async () => {
    logger.info(`dataLoad -- startData: ${startDate}, endDate: ${endDate}`);
    setIsLoading(true);
    const result = await Promise.all(fetchBase.map((d) => getElectricValue(d)));
    createNewGraphData({
      geData: result?.find((d) => d?.dataKey === "ge")?.data,
      sgData: result?.find((d) => d?.dataKey === "sg")?.data,
    }).then(() => {
      logger.info("dataLoad completed.");
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (ge === 0 && sg === 0) {
      setGraphData([]);
      setIsNoData(true);
      setIsLoading(false);
    }

    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    cancelToken = axios.CancelToken.source();

    if (startDate === "" || vesselId === null || (ge === 0 && sg === 0)) return;
    setIsNoData(false);
    dataLoad();
  }, [vesselId, startDate, endDate, ge, sg]);

  useEffect(() => {
    return () => {
      unmount.current = true;
      setIsLoading(false);
    };
  }, []);

  return {
    graphData,
    isLoading,
    isNoData,
  };
};
