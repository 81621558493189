import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, TableBody, TableContainer, TableCell, TableRow, Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import makeStyles from '@mui/styles/makeStyles';
import { withTranslation } from "react-i18next";
import * as PropTypes from "prop-types";

import localizationKeys from "../../../i18n/localizationKeys";
import { useElectricLatestData } from "./useElectricLatestData";
import { GE_MAX_POWER, SG_MAX_POWER } from "./electricUtil";
import {
  selectedChartDisplayType,
  setSelectedChartDisplayType,
} from "../../../model/slice/chartDisplayTypeSlice";
import {
  TOGGLE_OPTION_LIST,
  MIN_ELECTRIC_PAPER_HEIGHT,
  GENERATOR_TYPE,
} from "../../../constants/monitoring/constants";

import GeChart from "./geChart.jsx";
import SgChart from "./sgChart.jsx";
import ElectricChart from "./electricChart.jsx";
import { NoData } from "../../offlineComponent/noData.jsx";
import CustomToggleButton from "../../common/toggleButton.jsx";

const useStyles = (generatorCount) =>
  makeStyles((theme) => ({
    toggleContainer: {
      top: "10px",
      right: "10px",
      position: "absolute",
    },
    toggle: {
      position: "absolute",
      right: "0",
    },
    totalElectricChart: {
      border: "solid white",
    },
    electricChart: {
      height: `${MIN_ELECTRIC_PAPER_HEIGHT - theme.spacing(2) * 2}px`,
    },
    flex: {
      display: "flex",
    },
    electricChartList: {
      margin: "auto",
      display: "flex",
      height: "100%",
    },
    listContainer: {
      paddingTop: "20px",
      width: "100%",
      paddingBottom: "20px",
    },
    dataTable: {
      maxWidth: "80%",
      "& .MuiTableCell-root": {
        padding: "8px 16px",
      },
      "& .MuiTableCell-body": {
        color: "#ffffff",
        fontSize: "12px",
        fontWeight: 300,
        fontFamily: "Inter",
      },
      "& .MuiTableRow-root": {
        "&:not(:last-child) .MuiTableCell-root": {
          borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
        },
        "&:last-child .MuiTableCell-root": {
          borderBottom: "none",
        },
      },
      "& .MuiTableCell-head": {
        borderBottom: "none !important",
        width: "105px",
        textAlign: "right",
        color: "#ffffff",
        fontSize: "12px",
        fontWeight: 500,
        fontFamily: "Inter",
      },
    },
  }));

const Electric = (props) => {
  const dispatch = useDispatch();
  const { t, vesselId, vesselSettings, isInitialLoading, classes } = props;
  const {
    ge,
    sg,
    total,
    isNoData: isElectricNoData,
    isLoading: isElectricLoading,
    isNoElectric,
  } = useElectricLatestData(vesselId, vesselSettings?.NoDG, vesselSettings?.NoSG);
  const selectedChart = useSelector(selectedChartDisplayType);
  const electricClasses = {
    ...useStyles(Math.max(vesselSettings?.NoDG + vesselSettings?.NoSG, 0))(),
    ...classes,
  };

  const calculateLimitSum = () => {
    if (!ge.length && !sg.length) {
      return 0;
    }
    return (
      ge.length * (vesselSettings?.geMaxKw ?? GE_MAX_POWER) +
      sg.length * (vesselSettings?.sgMaxKw ?? SG_MAX_POWER)
    );
  };

  const handleDataChange = (e, value) => {
    value !== undefined &&
      value !== null &&
      (() => {
        dispatch(setSelectedChartDisplayType(value));
      })();
  };

  const powerTotalDisplay = (power) => (isElectricNoData || isNoElectric ? "?" : `${power ?? 0}`);

  const powerDisplay = (power) => (isElectricNoData ? "?" : `${power ?? 0}kW`);

  const loadDisplay = (power) =>
    isElectricNoData || isNoElectric
      ? "?"
      : `${parseInt(((power ?? 0) / vesselSettings?.geMaxKw) * 100)}%`;

  const generatorData = useMemo(() => {
    if (!ge.length && !sg.length) {
      return [];
    }
    return [
      ...ge.map((d) => {
        return {
          generatorNumber: `${d.generatorNumber} D/G`,
          power: powerDisplay(d.gePower),
          load: loadDisplay(d.gePower),
          runtime: d.geRunningTime,
        };
      }),
      ...sg.map((d) => {
        return {
          generatorNumber: `${d.generatorNumber} S/G`,
          power: powerDisplay(d.sgPower),
          load: loadDisplay(d.sgPower),
          runtime: d.sgRunningTime,
        };
      }),
    ];
  }, [ge, sg]);

  return (
    <React.Fragment>
      <Grid container xs={12} className={electricClasses.paper} sx={{position: "relative"}}>
        {isNoElectric || isElectricNoData ? (
          <Grid xs={12}>
            <NoData />
          </Grid>
        ) : (
          <>
            <Box className={electricClasses.toggleContainer}>
              <div className={electricClasses.toggle}>
                <CustomToggleButton
                  options={TOGGLE_OPTION_LIST}
                  selectedOption={selectedChart}
                  handler={handleDataChange}
                />
              </div>
            </Box>
            <Grid lg={3} md={3} sm={12} xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={electricClasses.electricChart}
              >
                <Grid container justifyContent="center" alignItems="center">
                  <ElectricChart
                    key={"electric-total"}
                    title={t(localizationKeys.TotalPower_upper)}
                    data={powerTotalDisplay(total)}
                    limit={calculateLimitSum()}
                    type={GENERATOR_TYPE.TOTAL}
                    sizeRatio={1}
                    className={electricClasses.totalElectricChart}
                    isLoading={isElectricLoading}
                    isNoData={isElectricNoData}
                    isNoElectric={isNoElectric}
                    isInitialLoading={isInitialLoading}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid lg={9} md={9} sm={12} xs={12} className={electricClasses.flex}>
              <div className={electricClasses.listContainer}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-evenly"
                  className={electricClasses.electricChartList}
                >
                  <GeChart
                    geMaxPower={vesselSettings?.geMaxKw ?? GE_MAX_POWER}
                    generatorData={ge}
                    noDG={vesselSettings?.NoDG}
                    isLoading={isElectricLoading}
                    isNoData={isElectricNoData}
                    isInitialLoading={isInitialLoading}
                    className={electricClasses.chart}
                    selectedChart={selectedChart.name}
                  />
                  <SgChart
                    engineData={sg}
                    noSG={vesselSettings?.NoSG}
                    isLoading={isElectricLoading}
                    isNoData={isElectricNoData}
                    isInitialLoading={isInitialLoading}
                    sgMaxKw={vesselSettings?.sgMaxKw ?? SG_MAX_POWER}
                    className={electricClasses.chart}
                    selectedChart={selectedChart.name}
                  />
                  {generatorData?.length > 0 && !isElectricLoading && selectedChart.name === "bar" && (
                    <Grid container xs={12} style={{ height: "155px", justifyContent: "center" }}>
                      <TableContainer className={electricClasses.dataTable}>
                        <Table aria-label="customized table">
                          <TableBody>
                            <TableRow className="row-style">
                              <TableCell variant="head">
                                {t(localizationKeys.Generator_upper)}
                              </TableCell>
                              {generatorData.map((d) => (
                                <TableCell key={`generator-${d.generatorNumber}`} align="center">
                                  {d.generatorNumber}
                                </TableCell>
                              ))}
                            </TableRow>
                            <TableRow className="row-style">
                              <TableCell variant="head">
                                {t(localizationKeys.Power_upper)}
                              </TableCell>
                              {generatorData.map((d) => (
                                <TableCell key={`power-${d.generatorNumber}`} align="center">
                                  {d.power}
                                </TableCell>
                              ))}
                            </TableRow>
                            <TableRow className="row-style">
                              <TableCell variant="head">{t(localizationKeys.Load_upper)}</TableCell>
                              {generatorData.map((d) => (
                                <TableCell key={`load-${d.generatorNumber}`} align="center">
                                  {d.load}
                                </TableCell>
                              ))}
                            </TableRow>
                            <TableRow className="row-style">
                              <TableCell variant="head">
                                {t(localizationKeys.RunningTime_upper)}
                              </TableCell>
                              {generatorData.map((d) => (
                                <TableCell key={`runtime-${d.generatorNumber}`} align="center">
                                  {[-1, null, undefined].includes(d.runtime) ? "*" : d.runtime}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  )}
                </Grid>
              </div>
            </Grid>
          </>
        )}
      </Grid>
    </React.Fragment>
  );
};

Electric.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Electric);
