import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { CircularProgress, Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { CARGO_STYLES } from "../../../constants/cargo/styles";
import { LineChart } from "../../chart/lineChart.jsx";
import MaximizeIcon from "../../trendPage/icon/maximizeIcon.jsx";
import MinimiseIcon from "../../trendPage/icon/minimiseIcon.jsx";
import { CARGO_CHARTS, PRESS_TABS } from "../../../constants/cargo/constants";
import { useDispatch, useSelector } from "react-redux";
import { getCargoDataDuration } from "../../../model/slice/dataDurationSlice";
import { CsvDownload } from "../../trendPage/commonComponent/csvDownload.jsx";
import {
  HANDLING_PRESS_LOAD_TAGS,
  HANDLING_PRESS_UNLOAD_TAGS,
} from "../../../constants/cargo/charts";
import { fetchPressureTrendData } from "../useTrendCargoData";
import { NoData } from "../../offlineComponent/noData";
import { setIsChannelTrendFetching } from "../../../model/slice/channelSlice.js";
import localizationKeys from "../../../i18n/localizationKeys.js";
import { useTranslation } from "react-i18next";
import { InlineTabSelector } from "../../tabSelector/inlineTabSelector.jsx";
import dayjs from "util/dayjs-init.js";
import { DATE_FORMAT } from "../../../constants/constants.js";
import { GRAPH_TYPE } from "../../../constants/trendGraph/trendGraphType.js";

const useStyles = makeStyles(() => ({
  container: {
    ...CARGO_STYLES.paper,
    paddingBottom: "20px",
  },
  titleContainer: {
    padding: "10px 24px",
    height: "43px",
    borderBottom: "solid 1.3px #222831",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "18px",
  },
  picker: {
    textAlign: "right",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    "& div": {
      width: '25px',
      height: '20px',
      marginLeft: "12px",
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      "& img": {
        cursor: "pointer",
      },
    },
  },
  contentContainer: {
    width: "100%",
    height: "calc(100% - 43px)",
    padding: "5px 24px 0 24px",
  },
}));

const commonTabs = (t) => [
  {
    name: t(localizationKeys.CargoLoading),
    id: PRESS_TABS.LOADING,
  },
  {
    name: t(localizationKeys.CargoUnloading),
    id: PRESS_TABS.UNLOADING,
  },
];

export const Pressure = (props) => {
  const { vesselId, isMaximized, setMaximize, selectedChart, setSelectedChart } = props;
  const { t } = useTranslation();
  const styles = useStyles();
  const dispatch = useDispatch();
  const duration = useSelector(getCargoDataDuration);
  const data = fetchPressureTrendData({
    vesselId,
    duration,
    interval: duration.interval,
  });

  const clickHandler = (id) => {
    setSelectedChart(id);
  };

  const formatData = (data) => {
    const paramList =
      selectedChart === PRESS_TABS.LOADING ? HANDLING_PRESS_LOAD_TAGS : HANDLING_PRESS_UNLOAD_TAGS;
    return data.map((val) => {
      const obj = {};
      paramList.forEach((tag) => {
        obj[tag.tag] = val[tag.tag];
      });
      obj.dateTime = dayjs.utc(val.dateTime).format(DATE_FORMAT);
      return obj;
    });
  };

  useEffect(() => {
    dispatch(setIsChannelTrendFetching(data.isLoading));
  }, [data.isLoading]);

  return (
    <Grid container className={styles.container}>
      <Grid item xs={12} className={styles.titleContainer}>
        <div>{t(localizationKeys.CargoTemperatureAndPressure)}</div>
        <div className={styles.picker}>
          <div>
            <CsvDownload
              vesselId={vesselId}
              graphData={data?.isLoading ? [] : formatData(data?.cargoPressData[selectedChart])}
              graphType={GRAPH_TYPE.CARGO}
              selectedChart={selectedChart}
              isCargo={true}
              isNoData={data?.isLoading}
            />
          </div>
          <div onClick={() => setMaximize(CARGO_CHARTS.PRESSURE)}>
            {
              isMaximized
              ? <MinimiseIcon width={20} height={20} disabled={data?.isLoading} />
              : <MaximizeIcon width={20} height={20} disabled={data?.isLoading} />  
            }
          </div>
        </div>
      </Grid>
      <Grid container className={styles.contentContainer}>
        <InlineTabSelector
          tabs={commonTabs(t)}
          selectedOne={selectedChart}
          clickHandler={clickHandler}
        />
        {data.isLoading ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : !data?.cargoPressData[selectedChart]?.length ? (
          <NoData />
        ) : (
          <LineChart
            data={data?.cargoPressData[selectedChart]}
            vesselId={vesselId}
            currentTab={selectedChart}
            isOneSec={duration.interval === 0}
          />
        )}
      </Grid>
    </Grid>
  );
};

Pressure.propTypes = {
  vesselId: PropTypes.string,
  isMaximized: PropTypes.bool,
  setMaximize: PropTypes.func,
  chartId: PropTypes.string,
  selectedChart: PropTypes.string,
  setSelectedChart: PropTypes.func,
};
