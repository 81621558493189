import { withStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CircularProgress, Grid } from "@mui/material";
import { withTranslation } from "react-i18next";

import dayjs from "../../../../util/dayjs-init";

import { Weather } from "./weather";
import { NAVIGATION_TYPES } from "../../../../constants/constants";
import { COLORS } from "../../../../constants/colors";
import useInterval from "../../../../hooks/useInterval";
import { fetchWeatherData } from "../../../../api/weather";
import { SeaWaterTemp } from "./seaWaterTemp";
import localizationKeys from "../../../../i18n/localizationKeys";

const styles = () => ({
  container: {
    display: "flex",
    height: "100% !important",
  },
  circle: {
    margin: "20px 20px",
    position: "relative",
    left: "calc(50% - 20px)",
    top: "calc(50% - 20px)",
  },
  text: {
    width: "100%",
    fontSize: "12px",
  },
  labelColor: {
    color: COLORS.greenishBlue,
  },
  valueColor: {
    color: COLORS.white70,
  },
  center: {
    textAlign: "center",
  },
});

export const DATE_TIME_FORMAT = "MM/DD HH:mm z";

const Temperature = ({ vessel, navigationData, style, classes, t }) => {
  const [gpggaData, setGpggaData] = useState(null);
  const [weatherData, setWeatherData] = useState(null);
  const gpgga = navigationData.find(d => d.navigationType === NAVIGATION_TYPES.GPGGA);

  const fetchWeather = async () => {
    if (!gpggaData) return;

    await fetchWeatherData(gpggaData.latitude, gpggaData.longitude)
      .then((data) => data && setWeatherData(data))
      .catch((e) => {
        console.log(`Error: ${e.message}`);
      });
  };

  useEffect(() => {
    fetchWeather();
  }, [gpggaData]);

  useInterval(
    () => fetchWeather(),
    1000 * 60 * 60
  );

  useEffect(() => {
    if (!gpgga) return;

    const details = gpgga.data[0];

    setGpggaData({
      latitude: details.latitude.toFixed(2),
      longitude: details.longitude.toFixed(2),
    });
  }, [gpgga]);

  useEffect(() => {
    return () => {
      setGpggaData(null);
      setWeatherData(null);
    };
  }, [vessel.vessel_id])

  return gpggaData && weatherData ?
    <Grid container className={ `${style.components} ${classes.container}` } rowGap={2}>
      <Grid item xs={12} className={`${classes.center}`}>
        <span className={`${classes.labelColor} ${classes.text}`}>{t(localizationKeys.LocalTime)}:</span> {dayjs(gpggaData.dateTime).format(DATE_TIME_FORMAT)}
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6} className={`${classes.center}`}>
            <div className={`${classes.labelColor} ${classes.text}`}>
              {t(localizationKeys.LocalWeather)}
            </div>
            <Weather weather={weatherData.current_weather} />
          </Grid>
          <Grid item xs={6} className={`${classes.center}`}>
            <div className={`${classes.labelColor} ${classes.text}`}>
              {t(localizationKeys.SeaWaterTemp)}
            </div>
            <SeaWaterTemp hourlyData={weatherData.hourly} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  : <div className={ `${style.components} ${classes.container} ${classes.center}` }>
    <CircularProgress className={classes.circle} />
  </div>;
}

Temperature.propTypes = {
  vessel: PropTypes.object,
  navigationData: PropTypes.array,
  style: PropTypes.object,
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  t: PropTypes.func,
};

export default withStyles(styles)(withTranslation()(Temperature));