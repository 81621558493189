import { useSelector } from "react-redux";
import { selectedFOCDuration } from "../../../model/slice/fleetNavigationDataSlice";
import { useEffect, useRef, useState } from "react";
import { baseNoon, baseTwoHours, getFocData } from "../focCore";
import { fetchFOCEngineData } from "../../../api/baseEngineData";
import dayjs from 'util/dayjs-init.js';
import { fetchVesselLatestEngineData } from "../../../api/latestData";
import useInterval from "../../../hooks/useInterval";
import { logger } from "../../../api/logger";

// check 2h FOC and TOTAL FOC data
export const useFocCalculation = ({ vesselId, flowmeterType }) => {
  const selectedFocDuration = useSelector(selectedFOCDuration);
  const unmount = useRef(false);
  const [currentFoc, setCurrentFoc] = useState(null);

  const getEngineData = async (latestDateTime) => {
    const { endDateTime, startDateTime } =
      selectedFocDuration?.duration === 24
        ? baseNoon(latestDateTime)
        : baseTwoHours(latestDateTime);
    // const {ge, me} = await readFoc(endDateTime.format());
    // if (ge?.length && me?.length) {
    //   unmount.current || setCurrentFoc({geTotal: ge.data, meTotal: me.data, latestDate: endDateTime.format()});
    //   return null;
    // }

    try {
      const data = await fetchFOCEngineData(
        vesselId,
        dayjs(startDateTime).format(),
        dayjs(endDateTime).format(),
        0
      );
      logger.info(`getEngineData data: ${JSON.stringify(data)}`);
      if (data.vesselEngineData) {
        return {
          // If there are "-1" data in fetched data, It will be removed.
          engineData: data.vesselEngineData.filter(
            (d) => d?.mefoFlowTotal && d.mefoFlowTotal !== -1
          ),
          startDate: startDateTime,
          endDate: endDateTime,
        }; //If data is there return
      }
    } catch (e) {
      logger.error(`getEngineData error: ${e}`);
      return null; //if we can't get data, just return
    }

    return null; //if we can't get data, just return
  };

  const getLatestEngineData = async () => {
    try {
      const data = await fetchVesselLatestEngineData(vesselId);
      if (data.latestEngineData[0]) {
        return data.latestEngineData[0]; //If data is there return
      }
    } catch (e) {
      logger.error(`getLatestEngineData error: ${e}`);
      return null; //if we can't get data, just return
    }

    return null; //if we can't get data, just return
  };

  const getFoc = async () => {
    const latestEngineData = await getLatestEngineData();
    if (!latestEngineData) return;

    const engineData = await getEngineData(latestEngineData.dateTime);
    logger.info(`getFoc engineData: ${JSON.stringify(engineData)}`);
    const foc = getFocData(engineData.engineData);

    // unmount.current || insertFoc(foc, engineData.startDate, engineData.endDate);
    try {
      unmount.current ||
        setCurrentFoc(
          foc
            ? Object.assign(
                {},
                ...Object.entries(foc).map(([k, v]) =>
                  k === "latestDate" ? { [k]: v } : { [k]: v * flowmeterType }
                )
              )
            : null
        );
    } catch (e) {
      logger.error(`getFoc error ${e}`);
      throw e;
    }
  };

  useEffect(() => {
    return () => {
      unmount.current = true;
    };
  }, []);

  useEffect(() => {
    getFoc();
  }, [selectedFocDuration, vesselId, flowmeterType]);

  useInterval(
    () => {
      if (selectedFocDuration?.duration === 2) {
        getFoc();
      }
    },
    60 * 10 * 1000,
    false
  ); // automatic update for 10 interval for 2hours FOC

  return {
    currentFoc,
  };
};
