import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import dayjs from 'util/dayjs-init.js';
import { Paper } from "@mui/material";

//Localization Imports
import { withTranslation } from "react-i18next";
import localizationKeys from "../../i18n/localizationKeys.js";
import {
  FOC_DURATION_LIST,
  selectedFOCDuration,
  setSelectedFOCDuration,
} from "../../model/slice/fleetNavigationDataSlice";
import clsx from "clsx";
import CustomToggleButton from "../common/toggleButton.jsx";

const useStyles = makeStyles((theme) => ({
  sepDataHolder: {
    "& td": {
      paddingTop: "15px",
    },
    width: "100%",
  },
  iconLabel: {
    position: "absolute",
    top: "25px",
    left: "26px",
    fontSize: "13px",
  },
  iconLabelSelected: {
    color: "#ffffff",
  },
  iconHolder: {
    cursor: "pointer",
    position: "relative",
  },
  navValueRight: {
    textAlign: "right",
  },
  geFailureMsg:{
    color: "#ffffff",
    fontSize: "13px",
  }
}));

const SepDataPanel = (props) => {
  const { t, isDisconnected } = props;
  const dispatch = useDispatch();
  const styles = useStyles();
  const classes = { ...styles, ...props.classes };
  const [geFoc, setGeFoc] = React.useState();
  const [focDate, setFocDate] = React.useState();
  const [geFailure, setGeFailure] = React.useState();
  const selectedFocDuration = useSelector(selectedFOCDuration);

  const focUnit = () => {
    if (selectedFocDuration?.duration === 24) {
      return "KL/day";
    } else if (selectedFocDuration?.duration === 2) {
      return "KL/2hours";
    } else {
      return null;
    }
  };

  React.useEffect(() => {
    if (props.focData === undefined || props.focData === null) {
      return;
    }
    setFocDate(props.focData.latestDate);
    setGeFoc(props.focData.geTotal);
    setGeFailure(props.focData.geFailure);
  }, [props.focData]);

  const handleFocDataChange = (e, value) => {
    value !== undefined &&
      value !== null &&
      (() => {
        dispatch(setSelectedFOCDuration(value));
      })();
  };

  return (
    <React.Fragment>
      <Paper className={isDisconnected ? classes.disconnectedPaperTitle : classes.paperTitle}>
        <table width="100%">
          <tbody>
            <tr align="right">
              <td width="60%">
                <p>{t(localizationKeys.BasicSepData_upper)}</p>
              </td>
              <td>
                <CustomToggleButton
                  options={FOC_DURATION_LIST}
                  selectedOption={selectedFocDuration}
                  handler={handleFocDataChange}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Paper>
      <table className={classes.sepDataHolder}>
        <tbody>
          <tr>
            <td>
              <div className={isDisconnected ? classes.disconnectedNavLabel : classes.navLabel}>
                {t(localizationKeys.TotalDieselEngineFoc_upper)}
              </div>
            </td>
            <td>
              {/* Generator Engine FOC */}
              <div
                data-testid="generator_engine_foc_data"
                className={clsx(classes.navValue, classes.navValueRight)}
              >
                {!isNaN(geFoc) ? (geFoc * 0.001).toFixed(2) : "-"} {focUnit()}(
                {focDate !== undefined ? dayjs(focDate).format("MM/DD") : ""})
              </div>
            </td>
          </tr>
        </tbody>
      </table>
          {(() => {
            if (geFailure?.length > 0) {
              return (
                <div className={clsx(classes.navValueRight, classes.geFailureMsg)}>
                  *Generator # {geFailure.join(", # ")} flow counter(s) failed.
                </div>
              );
            }
          })()}
      <table className={classes.sepDataHolder}>
        <tbody>
          <tr>
            <td>
              <div className={isDisconnected ? classes.disconnectedNavLabel : classes.navLabel}>
                {t(localizationKeys.Trim_upper)}
              </div>
            </td>
            <td>
              {(() => {
                //Trim
                if (props.sepData !== undefined) {
                  const trimValue = props.sepData.trimValue;

                  return (
                    <div className={clsx(classes.navValue, classes.navValueRight)}>
                      {trimValue === undefined ? "-" : trimValue.toFixed(2)} DEG
                    </div>
                  );
                } else {
                  return <div className={clsx(classes.navValue, classes.navValueRight)}>- DEG</div>;
                }
              })()}
            </td>
          </tr>
          <tr>
            <td>
              <div className={isDisconnected ? classes.disconnectedNavLabel : classes.navLabel}>
                {t(localizationKeys.Heel_upper)}
              </div>
            </td>
            <td>
              {(() => {
                //Heel
                if (props.sepData !== undefined) {
                  const heelValue = props.sepData.heelValue;
                  return (
                    <div className={clsx(classes.navValue, classes.navValueRight)}>
                      {heelValue === undefined ? "-" : heelValue.toFixed(2)} DEG
                    </div>
                  );
                } else {
                  return <div className={clsx(classes.navValue, classes.navValueRight)}>- DEG</div>;
                }
              })()}
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

SepDataPanel.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  t: PropTypes.func.isRequired,
  sepData: PropTypes.object,
  focData: PropTypes.object,
  isDisconnected: PropTypes.bool,
};

export default withTranslation()(SepDataPanel);
