import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import makeStyles from '@mui/styles/makeStyles';
import { withTranslation } from "react-i18next";
import clsx from "clsx";

import { selectedChartDisplayType } from "../../../model/slice/chartDisplayTypeSlice";
import { GENERATOR_TYPE } from "../../../constants/monitoring/constants";
import localizationKeys from "../../../i18n/localizationKeys";

import BarChartContainer from "./chart/barChart.jsx";
import PieChartContainer from "./chart/pieChart.jsx";

const useStyles = makeStyles(() => ({
  base: {
    display: "flex",
    justifyContent: "center",
  },
  chartTotalContainer: {
    width: "100%",
    marginTop: "12px",
    display: "flex",
    justifyContent: "center",
  },
  chartTotalLabel: {
    minWidth: "121px",
    minHeight: "30px",
    fontSize: "24px",
    fontWeight: 600,
    fontFamily: "Inter",
    color: "rgba(255, 255, 255, 0.7)",
  },
  chartTotalValue: {
    minWidth: "103px",
    minHeight: "30px",
    borderRadius: "4px",
    background: "rgba(11, 13, 16, 0.5)",
    padding: "5px 10px",
    textAlign: "right",
    color: "#ffffff",
    fontSize: "16px",
  },
  chartLabel: {
    width: "60px",
    fontSize: "16px",
    fontFamily: "Inter",
    color: "rgba(255, 255, 255, 0.7)",
  },
  chartRect: {
    width: "32px",
    height: "16px",
    marginTop: "2px",
    borderRadius: "4px",
  },
  hasValue: {
    background: "#19b2ab",
  },
  noValue: {
    background: "#e37c8e",
  },
}));

const ElectricChart = (props) => {
  const selectedChart = useSelector(selectedChartDisplayType);
  const [electricData, setElectricData] = useState([]);
  const styles = useStyles();

  useEffect(() => {
    if (props.data === "?") {
      setElectricData([
        { name: "load", value: 0 },
        {
          name: "unload",
          value: 0,
        },
      ]);
    } else {
      setElectricData([
        { name: "load", value: Number(props.data) },
        {
          name: "unload",
          value: Number(props.limit) - Number(props.data),
        },
      ]);
    }
  }, [props.data, props.limit]);

  const DisplayLabel = () => {
    switch (props.type) {
      case GENERATOR_TYPE.TOTAL:
        return (
          <div className={styles.chartTotalContainer}>
            <div className={styles.chartTotalLabel}>{props.t(localizationKeys.Total_upper)}</div>
            <div className={styles.chartTotalValue}>{props.data}</div>
          </div>
        );
      default:
        const geOpen = !props.isNoData && props.condition;
        return (
          <div className={styles.chartTotalContainer}>
            <div className={styles.chartLabel}>{props.title}</div>
            <div
              className={clsx(styles.chartRect, geOpen ? styles.noValue : styles.hasValue)}
            ></div>
          </div>
        );
    }
  };

  return (
    <React.Fragment>
      {selectedChart.name === "bar" ? (
        <BarChartContainer {...props} electricData={electricData} classes={styles} />
      ) : (
        <PieChartContainer {...props} electricData={electricData} classes={styles} />
      )}
      {!props.isLoading && <DisplayLabel />}
    </React.Fragment>
  );
};

export default withTranslation()(ElectricChart);
