import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import dayjs from 'util/dayjs-init.js';

//Design imports
import { getHighlightStyle } from "../../../helpers/alarmTypeStyle.js";
import { useTheme } from "@mui/material/styles";
import { CircularProgress, useMediaQuery } from "@mui/material";
import withStyles from '@mui/styles/withStyles';

//Table design imports
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

//Localization Imports
import { withTranslation } from "react-i18next";
import localizationKeys from "../../../i18n/localizationKeys.js";
import {
  TableCellStyled,
  TableRowStyled,
  TableCellStyledForFunction,
} from "../utils/tableStyled.jsx";
import constants from "../utils/constants.js";
import { getComparator, stableSort } from "../utils/helper.js";
import { useDispatch, useSelector } from "react-redux";
import {
  isCurrentAlarmLoading,
  currentWindowPosition,
  currentTablePosition,
  setCurrentWindowPosition,
  setCurrentTablePosition,
} from "../../../model/slice/alarmSlice.js";
import { selectedTimezone } from "../../../model/slice/timezoneSlice.js";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside.js";

import SortIcon from "../../../../assets/images/alarmIcon/sort-icon.svg";
import SortIconPrimary from "../../../../assets/images/alarmIcon/sort-icon-primary.svg";
import ResetIcon from "../../../../assets/images/alarmIcon/reset-icon.svg";
import ResetIconPrimary from "../../../../assets/images/alarmIcon/reset-icon-primary.svg";
import CalenderIconSetColor from "../../common/calenderIconSetColor.jsx";
import CloseIcon from "../../../../assets/images/alarmIcon/close-icon.svg";
import { MobileSortIcon } from "../../common/listUtil/mobileSortIcon.jsx";
import SortUpIconSetColor from "../../common/SortUpIconSetColor.jsx";
import SortDownIconSetColor from "../../common/SortDownIconSetColor.jsx";
import { formatDate } from "../../../util/timezone.js";


const settingHolder = {
  padding: "10px 0px",
  backgroundColor: "rgba(44, 58, 72, 1.0)",
  position: "absolute",
  color: "#ffffff",
  fontSize: "12px",
};

const styles = (theme) => ({
  circleStatus: {
    height: "20px",
    width: "20px",
    borderRadius: "50%",
    display: "inline-block",
    [theme.breakpoints.down('sm')]: {
      height: "10px",
      width: "10px",
    },
  },
  noAlarmStyle: {
    paddingTop: "50px",
    fontSize: "16px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "10px",
    },
  },
  headerHolder: {
    height: "38px",
    [theme.breakpoints.down('sm')]: {
      height: "24px",
      fontSize: "10px !important",
    },
    "& .MuiTableCell-root": {
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "Inter",
      fontStretch: "normal",
      lineHeight: "normal",
      color: "#ffffff",
      [theme.breakpoints.down('sm')]: {
        fontSize: "10px",
      },
    },
    "& .MuiSvgIcon-root": {
      fill: "white",
    },
  },
  columnHeader: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  userSettingHolder: {
    ...settingHolder,
    top: "44px",
    width: "250px",
    right: "0",
    borderRadius: "2px",
    [theme.breakpoints.down('md')]: {
      top: "38px",
      right: "6%",
    },
    [theme.breakpoints.down('sm')]: {
      top: "auto",
      bottom: "8%",
      right: "6%",
      position: "fixed",
    },
  },
  settingsTable: {
    width: "100%",
    borderSpacing: "0",
  },
  settingRow: {
    height: "35px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(38, 48, 59) !important",
    },
    "& td": {
      paddingLeft: "10px",
    },
  },
  selectColor: {
    backgroundColor: "rgb(33, 38, 47) !important",
  },
  mobileSort: {
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "8px",
    position: "fixed",
    right: "30px",
    bottom: "60px",
    width: "30px",
    height: "30px",
    background: "#2c3a48",
    "&:hover": {
      background: "#2c3a48",
    },
  },
  mobileSortIcon: {
    height: "20px",
    width: "20px",
    borderRadius: "50%",
    display: "inline-block",
  },
  mobileRestSort: {
    marginTop: "10px",
    width: "60%",
    height: "30px",
    borderRadius: "5px 5px 5px 5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#21262f",
    "&:hover": {
      background: "#21262f",
    },
  },
  settingRowMobile: {
    height: "35px",
    cursor: "pointer",
  },
  mobileCloseIcon: {
    width: "20px",
    borderRadius: "5px 5px 5px 5px",
    display: "flex",
    justifyContent: "right",
  },
  rowLeft: {
    width: "25px",
    "& > img": {
      position: "relative",
      left: "3px",
    },
  },
  rowLeftForCalender: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "10px",
  },
  whiteText: {
    color: '#bec2c5',
  },
});

const AlarmListTable = React.memo((props) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const ALARM_DATE_FORMAT = "YYYY/MM/DD hh:mm";
  const { t, height } = props;
  const classes = { ...props.classes, ...props.layoutStyle };
  const alarmList = props.alarmList;
  const isLoading = useSelector(isCurrentAlarmLoading);
  const [initialLoad, setInitialLoad] = useState(true);
  const tabRef = useRef(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(constants.ALARM_HEADER_ID.OCCURRENCE); //set the default sort
  const [isDisplaySortDialog, setIsDisplaySortDialog] = useState(false);
  const menuRef = useRef(null);
  const dispatch = useDispatch();
  const currentWinPos = useSelector(currentWindowPosition);
  const currentTablePos = useSelector(currentTablePosition);
  const timezone = useSelector(selectedTimezone);
  let updateFlag = true;

  useEffect(() => {
    const windowScrollHandler = () => {
      const y = window.scrollY;
      if (y !== 0) dispatch(setCurrentWindowPosition(y));
    };
    window.addEventListener("scroll", windowScrollHandler);
    return () => {
      window.removeEventListener("scroll", windowScrollHandler);
    };
  }, []);

  useEffect(() => {
    setInitialLoad(true);
  }, [props.vesselId]);

  useEffect(() => {
    const table = document.getElementById("dataGrid");
    const tableScrollHandler = () => {
      const y = table.scrollTop;
      if (y !== 0) dispatch(setCurrentTablePosition(y));
    };

    if (updateFlag) {
      updateFlag = false;
      table.addEventListener("scroll", tableScrollHandler);
    }
    window.scrollTo(0, currentWinPos);
    table.scrollTo(0, currentTablePos);
    return () => {
      table.removeEventListener("scroll", tableScrollHandler);
    };
  }, [isLoading]);

  useOnClickOutside([menuRef], () => {
    setIsDisplaySortDialog(false);
  });

  const handleRequestSort = (property) => (event) => {
    const isAsc = orderBy === property && order === constants.ORDER.ASC;
    setOrder(isAsc ? constants.ORDER.DESC : constants.ORDER.ASC);
    setOrderBy(property);
  };

  const ResetSort = () => {
    setOrder("asc");
    setOrderBy(constants.ALARM_HEADER_ID.OCCURRENCE);
  };

  const CloseDialog = () => {
    setIsDisplaySortDialog(false);
  };

  const handleRequestSortForMenu = (property, selectorder) => (event) => {
    setOrder(selectorder);
    setOrderBy(property);
  };

  const TableHeader = () => (
    <TableHead>
      <TableRow className={classes.headerHolder}>
        <TableCellStyled
          key={`alarm-monitoring-table-header-1`}
          sortDirection={orderBy === constants.ALARM_HEADER_ID.ALARM_NAME ? order : false}
          onClick={handleRequestSort(constants.ALARM_HEADER_ID.ALARM_NAME)}
          className={classes.columnHeader}
        >
          {t(localizationKeys.AlarmName_lower)}
          <TableSortLabel
            active={orderBy === constants.ALARM_HEADER_ID.ALARM_NAME}
            direction={orderBy === constants.ALARM_HEADER_ID.ALARM_NAME ? order : "asc"}
          />
        </TableCellStyled>
        <TableCellStyled
          key={`alarm-monitoring-table-header-2`}
          align="left"
          sortDirection={orderBy === constants.ALARM_HEADER_ID.CATEGORY ? order : false}
          onClick={handleRequestSort(constants.ALARM_HEADER_ID.CATEGORY)}
          className={classes.columnHeader}
        >
          {t(localizationKeys.Category_lower)}
          <TableSortLabel
            active={orderBy === constants.ALARM_HEADER_ID.CATEGORY}
            direction={orderBy === constants.ALARM_HEADER_ID.CATEGORY ? order : "asc"}
          />
        </TableCellStyled>
        <TableCellStyled
          key={`alarm-monitoring-table-header-3`}
          align="left"
          sortDirection={orderBy === constants.ALARM_HEADER_ID.OCCURRENCE ? order : false}
          onClick={handleRequestSort(constants.ALARM_HEADER_ID.OCCURRENCE)}
          className={classes.columnHeader}
        >
          {t(localizationKeys.OccurrenceTime)}
          <TableSortLabel
            active={orderBy === constants.ALARM_HEADER_ID.OCCURRENCE}
            direction={orderBy === constants.ALARM_HEADER_ID.OCCURRENCE ? order : "asc"}
          />
        </TableCellStyled>
        <TableCellStyled
          key={`alarm-monitoring-table-header-4`}
          align="left"
          sortDirection={orderBy === constants.ALARM_HEADER_ID.UPDATE ? order : false}
          onClick={handleRequestSort(constants.ALARM_HEADER_ID.UPDATE)}
          className={classes.columnHeader}
        >
          {t(localizationKeys.UpdateTime)}
          <TableSortLabel
            active={orderBy === constants.ALARM_HEADER_ID.UPDATE}
            direction={orderBy === constants.ALARM_HEADER_ID.UPDATE ? order : "asc"}
          />
        </TableCellStyled>
        <TableCellStyled
          key={`alarm-monitoring-table-header-5`}
          colSpan={2}
          align="left"
          sortDirection={orderBy === constants.ALARM_HEADER_ID.ALARMSTATUS ? order : false}
          onClick={handleRequestSort(constants.ALARM_HEADER_ID.ALARMSTATUS)}
          className={classes.columnHeader}
        >
          {t(localizationKeys.Value_lower)}
          <TableSortLabel
            active={orderBy === constants.ALARM_HEADER_ID.ALARMSTATUS}
            direction={orderBy === constants.ALARM_HEADER_ID.ALARMSTATUS ? order : "asc"}
          />
        </TableCellStyled>

        {!isXs && (
          <TableCellStyledForFunction onClick={ResetSort}>
            <img
              src={
                order === "asc" && orderBy === constants.ALARM_HEADER_ID.OCCURRENCE
                  ? ResetIcon
                  : ResetIconPrimary
              }
              className={`${classes.circleStatus} ${classes.columnHeader}`}
            />
          </TableCellStyledForFunction>
        )}
        {!isXs && (
          <TableCellStyledForFunction
            onClick={() => {
              setIsDisplaySortDialog(!isDisplaySortDialog);
            }}
          >
            <img
              src={
                order === "asc" && orderBy === constants.ALARM_HEADER_ID.OCCURRENCE
                  ? SortIcon
                  : SortIconPrimary
              }
              className={`${classes.circleStatus} ${classes.columnHeader}`}
            />
          </TableCellStyledForFunction>
        )}
      </TableRow>
    </TableHead>
  );

  const displaySortDialog = () => {
    if (isDisplaySortDialog) {
      return (
        <div className={classes.userSettingHolder} ref={menuRef}>
          <table className={classes.settingsTable}>
            <tbody>
              {isXs && (
                <tr className={`${classes.settingRowMobile}`}>
                  <td colSpan="2" align="right" valign="top">
                    <div className={classes.mobileCloseIcon} onClick={CloseDialog}>
                      <img
                        src={CloseIcon}
                        className={`${classes.mobileSortIcon} ${classes.columnHeader}`}
                      />
                    </div>
                  </td>
                </tr>
              )}
              <tr
                className={`${classes.settingRow} ${
                  orderBy === constants.ALARM_HEADER_ID.ALARMSTATUS && order === constants.ORDER.ASC
                    ? classes.selectColor
                    : ""
                }`}
                onClick={handleRequestSortForMenu(
                  constants.ALARM_HEADER_ID.ALARMSTATUS,
                  constants.ORDER.ASC
                )}
              >
                <td className={classes.rowLeft}>
                  <SortUpIconSetColor color={orderBy === constants.ALARM_HEADER_ID.ALARMSTATUS && order === constants.ORDER.ASC? "white" : "#bec2c5"} /> 
                </td>
                <td className={`${orderBy !== constants.ALARM_HEADER_ID.ALARMSTATUS || order !== constants.ORDER.ASC ? classes.whiteText : ""}`}>
                  {t(localizationKeys.SortByHighestValue)}
                </td>
              </tr>
              <tr
                className={`${classes.settingRow} ${
                  orderBy === constants.ALARM_HEADER_ID.ALARMSTATUS &&
                  order === constants.ORDER.DESC
                    ? classes.selectColor
                    : ""
                }`}
                onClick={handleRequestSortForMenu(
                  constants.ALARM_HEADER_ID.ALARMSTATUS,
                  constants.ORDER.DESC
                )}
              >
                <td className={classes.rowLeft}>
                  <SortDownIconSetColor color={orderBy === constants.ALARM_HEADER_ID.ALARMSTATUS && order === constants.ORDER.DESC? "white" : "#bec2c5"} />   
                </td>
                <td className={`${orderBy !== constants.ALARM_HEADER_ID.ALARMSTATUS || order !== constants.ORDER.DESC ? classes.whiteText : ""}`}>
                  {t(localizationKeys.SortByLowestValue)}
                </td>
              </tr>
              <tr
                className={`${classes.settingRow} ${
                  orderBy === constants.ALARM_HEADER_ID.DATETIME && order === constants.ORDER.ASC
                    ? classes.selectColor
                    : ""
                }`}
                onClick={handleRequestSortForMenu(
                  constants.ALARM_HEADER_ID.DATETIME,
                  constants.ORDER.ASC
                )}
              >
                <td className={classes.rowLeftForCalender}>
                  <CalenderIconSetColor color={orderBy === constants.ALARM_HEADER_ID.DATETIME && order === constants.ORDER.ASC? "white" : "#bec2c5"} />
                </td>
                <td className={`${orderBy !== constants.ALARM_HEADER_ID.DATETIME || order !== constants.ORDER.ASC ? classes.whiteText : ""}`}>
                  {t(localizationKeys.SortByNewest)}
                </td>
              </tr>
              <tr
                className={`${classes.settingRow} ${
                  orderBy === constants.ALARM_HEADER_ID.DATETIME && order === constants.ORDER.DESC
                    ? classes.selectColor
                    : ""
                }`}
                onClick={handleRequestSortForMenu(
                  constants.ALARM_HEADER_ID.DATETIME,
                  constants.ORDER.DESC
                )}
              >
                <td className={classes.rowLeftForCalender}>
                  <CalenderIconSetColor color={orderBy === constants.ALARM_HEADER_ID.DATETIME && order === constants.ORDER.DESC? "white" : "#bec2c5"} />
                </td>
                  <td className={`${orderBy !== constants.ALARM_HEADER_ID.DATETIME || order !== constants.ORDER.DESC ? classes.whiteText : ""}`}>
                {t(localizationKeys.SortByOldest)}
                </td>
              </tr>
              <tr
                className={`${classes.settingRow} ${
                  orderBy === constants.ALARM_HEADER_ID.ALARM_NAME && order === constants.ORDER.DESC
                    ? classes.selectColor
                    : ""
                }`}
                onClick={handleRequestSortForMenu(
                  constants.ALARM_HEADER_ID.ALARM_NAME,
                  constants.ORDER.DESC
                )}
              >
              <td className={`${classes.rowLeft} ${orderBy !== constants.ALARM_HEADER_ID.ALARM_NAME || order !== constants.ORDER.DESC ? classes.whiteText : ""}`}>
                &ensp;A
              </td>
              <td className={`${orderBy !== constants.ALARM_HEADER_ID.ALARM_NAME || order !== constants.ORDER.DESC ? classes.whiteText : ""}`}>
                {t(localizationKeys.SortAlarmItemAZ)}
              </td>
            </tr>
              <tr
                className={`${classes.settingRow} ${
                  orderBy === constants.ALARM_HEADER_ID.ALARM_NAME && order === constants.ORDER.ASC
                    ? classes.selectColor
                    : ""
                }`}
                onClick={handleRequestSortForMenu(
                  constants.ALARM_HEADER_ID.ALARM_NAME,
                  constants.ORDER.ASC
                )}
              >
              <td className={`${classes.rowLeft} ${orderBy !== constants.ALARM_HEADER_ID.ALARM_NAME || order !== constants.ORDER.ASC ? classes.whiteText : ""}`}>
                &ensp;Z
              </td>
              <td className={`${orderBy !== constants.ALARM_HEADER_ID.ALARM_NAME || order !== constants.ORDER.ASC ? classes.whiteText : ""}`}>
                {t(localizationKeys.SortAlarmItemZA)}
              </td>
            </tr>
              {isXs && (
                <tr className={`${classes.settingRowMobile}`}>
                  <td colSpan="2" align="center">
                    <div className={classes.mobileRestSort} onClick={ResetSort}>
                      <img
                        src={ResetIcon}
                        className={`${classes.mobileSortIcon} ${classes.columnHeader}`}
                      />
                      {t(localizationKeys.ResetSort)}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      );
    } else {
      return null;
    }
  };

  const getAlarmList = useCallback(() => {
    return stableSort(alarmList, getComparator(order, orderBy), orderBy).map((row, idx) => (
      <TableRowStyled key={`${row.vessel_id}-${row.channelId}-${idx}`}>
        <TableCellStyled component="th" scope="row">
          {row[constants.AMS_CHANNEL_COLUMN]}
        </TableCellStyled>
        <TableCellStyled align="left">{row.category}</TableCellStyled>
        <TableCellStyled align="left">
          {row?.dateTime ? formatDate(row.dateTime, timezone, ALARM_DATE_FORMAT) : null}
        </TableCellStyled>
        <TableCellStyled align="left">
          {row?.updateDateTime ? formatDate(row.updateDateTime, timezone, ALARM_DATE_FORMAT) : null}
        </TableCellStyled>
        <TableCellStyled align="left" style={{ maxWidth: "20px", margin: "0 auto" }}>
          <span
            className={classes.circleStatus}
            style={getHighlightStyle({ alarmStatus: row.alarmStatus })}
          />
        </TableCellStyled>
        <TableCellStyled align="left">{row.value}</TableCellStyled>
      </TableRowStyled>
    ));
  }, [alarmList, timezone]);

  const displayAlarmList = () => {
    if (isLoading && initialLoad) {
      return (
        <TableRowStyled>
          <TableCellStyled colSpan={4} align="center">
            <CircularProgress />
          </TableCellStyled>
        </TableRowStyled>
      );
    } else if (alarmList?.length > 0) {
      return getAlarmList();
    } else if (isLoading) {
      return (
        <TableRowStyled>
          <TableCellStyled colSpan={4} align="center">
            <CircularProgress />
          </TableCellStyled>
        </TableRowStyled>
      );
    } else {
      return (
        <TableRowStyled>
          <TableCellStyled colSpan={4} align="center" className={classes.noAlarmStyle}>
            {t(localizationKeys.NoActiveAlarms_upper)}
          </TableCellStyled>
        </TableRowStyled>
      );
    }
  };

  return (
    <TableContainer
      id="dataGrid"
      style={{
        borderRadius: "8px",
        height: isMdUp ? "100%" : `calc(100vh - ${height}px)`,
        minHeight: "500px",
        position: "relative",
      }}
    >
      <Table aria-label="customized table" ref={tabRef}>
        <TableHeader />
        <TableBody>{displayAlarmList()}</TableBody>
      </Table>
      {isXs && (
        <div
          className={classes.mobileSort}
          onClick={() => {
            setIsDisplaySortDialog(!isDisplaySortDialog);
          }}
        >
          <MobileSortIcon
            color={orderBy == null ? "white" : "#19B2AB"}
            className={`${classes.mobileSortIcon} ${classes.columnHeader}`}
          />
        </div>
      )}
      {displaySortDialog()}
    </TableContainer>
  );
});

AlarmListTable.propTypes = {
  t: PropTypes.func.isRequired,
  alarmList: PropTypes.array,
  vesselId: PropTypes.string,
};

export default withStyles(styles)(withTranslation()(AlarmListTable));
